import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Alert,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Card,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { ProductList } from '../sections/@dashboard/products';
import PRODUCTS from '../_mock/products';
import { useAuthContext } from '../auth/useAuthContext';
import { isExpiredSubscription } from '../utils/formatTime';

const StyledProductImg = styled('img')({
  width: '50%',
  height: '50%',
  objectFit: 'cover',
});

const steps = ['Choose Template', 'Add Script', 'Embed Form'];

const StyledLabel = styled('p')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 15,
  marginBottom: 2,
  lineHeight: 1.5,
}));

let selectedForm = null;
let currentActiveStep = 0;

export default function ProductsPage() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProduct, setProduct] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const shouldHidePageOrRedirect = isExpiredSubscription(
    user.subscription.dateExpiry
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep - 1 === 0) {
      setProduct(null);
      selectedForm = null;
    }
  };

  const handleProductSelect = (product) => {
    setProduct(product);
    handleNext();
    fetch(product.form, { method: 'GET' })
      .then((res) => res.text())
      .then((formText) => {
        selectedForm = formText;
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (shouldHidePageOrRedirect) {
      return navigate('/dashboard', { replace: true });
    }

    window.addEventListener('copy', handleCopyCloneable);
    return () => {
      window.removeEventListener('copy', handleCopyCloneable);
      selectedForm = null;
      currentActiveStep = null;
    };
  }, []);

  useEffect(() => {
    currentActiveStep = activeStep;
  }, [activeStep]);

  const handleCopyCloneable = async (e) => {
    if (e && e.clipboardData && selectedForm && currentActiveStep === 2) {
      e.clipboardData.setData('application/json', selectedForm);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 4000);
      e.preventDefault();
    }
  };

  const cloneableHasThumbnailService = selectedProduct && selectedProduct.cloneableHasThumbnailService;
  // const flowBookingScriptTag = cloneableHasThumbnailService  ? 'https://res.cloudinary.com/de7fg4j42/raw/upload/v1684482333/cloneable/v1/flowBookings_v1.2_xjkcoh.js' : 'https://res.cloudinary.com/de7fg4j42/raw/upload/v1684482786/cloneable/v1/flowBookings_v1.1_mdvhlx.js';

  const flowBookingScriptTag = `https://res.cloudinary.com/de7fg4j42/raw/upload/v1705411709/cloneable/v2/flow-bookings-script-v1_yelzvg.js`;

  const scriptsToCopy = {
    finsweetComment: '<!-- [Attributes by Finsweet] Custom Form Select -->\r\n',
    finsweet: `<script defer src="https://cdn.jsdelivr.net/npm/@finsweet/attributes-selectcustom@1/selectcustom.js"></script>\r\n`,
    flowbookingsComment: '<!-- [Flow bookings tags] -->\r\n',
    flowbookings: `<script defer fb="flow-bookings" user="${user._id}" src="${flowBookingScriptTag}" servicetype="${cloneableHasThumbnailService ? 'radio' : 'select'}"></script>\r\n`,
    flowbokoingsStyles: `<link rel="stylesheet" href="https://res.cloudinary.com/de7fg4j42/raw/upload/v1684483376/cloneable/v1/webflow-styles_nuhrg9.css">`,
  };

  if (cloneableHasThumbnailService) {
    delete scriptsToCopy.finsweetComment;
    delete scriptsToCopy.finsweet;
  }

  const scriptsToCopyWithHtml = Object.keys(scriptsToCopy).map((key) => (
    <p>{scriptsToCopy[key]}</p>
  ));

  const scriptsToCopyToClipboard = Object.keys(scriptsToCopy)
    .map((key) => scriptsToCopy[key])
    .join('');

  return (
    <>
      <Helmet>
        <title> Form Builder | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      {shouldHidePageOrRedirect ? null : (
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 3 }}>
            Form Builder
          </Typography>
          {isCopied && (
            <Alert severity="success" color="info" sx={{ mb: 3 }}>
              Copied to clipboard
            </Alert>
          )}
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, _) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length - 1 ? (
              <Card
                sx={{ mt: 5, pt: '24px', pr: '20px', pl: '20px', pb: '24px' }}
              >
                <Stack
                  sx={{ margin: 'auto', textAlign: 'center', maxWidth: 900 }}
                >
                  <Typography variant="h4">
                    Add your form to webflow!
                  </Typography>
                  <Box sx={{ justifyContent: 'center', display: 'flex', p: 2 }}>
                    <StyledProductImg
                      alt={selectedProduct.name}
                      src={selectedProduct.cover}
                    />
                  </Box>
                  <Stack sx={{ margin: 'auto', maxWidth: 599 }}>
                    <StyledLabel>
                      Copy the booking form cloneable from the button below and
                      paste it on Webflow.
                    </StyledLabel>
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      pt: 2,
                      margin: 'auto',
                    }}
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={handleBack}
                    >
                      Go Back
                    </Button>
                    <LoadingButton
                      size="large"
                      id="test-me-daddy"
                      variant="contained"
                      sx={{ ml: 3 }}
                      isLoading={selectedForm !== null}
                      onClick={() => document.execCommand('copy')}
                    >
                      Copy Cloneable
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            ) : (
              <>
                {activeStep === 0 && (
                  <Card
                    sx={{
                      mt: 5,
                      pt: '24px',
                      pr: '20px',
                      pl: '20px',
                      pb: '24px',
                    }}
                  >
                    <ProductList
                      products={PRODUCTS}
                      setProduct={handleProductSelect}
                    />
                  </Card>
                )}
                {activeStep === 1 && (
                  <Card
                    sx={{
                      mt: 5,
                      pt: '24px',
                      pr: '20px',
                      pl: '20px',
                      pb: '24px',
                    }}
                  >
                    <Stack
                      sx={{
                        margin: 'auto',
                        textAlign: 'center',
                        maxWidth: 900,
                      }}
                    >
                      <Typography variant="h4">
                        Add Script to Webflow
                      </Typography>
                      <StyledLabel>
                        {
                          'Copy the <script> and paste it into <head> of the page where you want to insert the form.'
                        }
                      </StyledLabel>

                      <Stack
                        sx={{
                          background: '#F4F6F8',
                          p: 3,
                          mt: 3,
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                          }}
                        >
                          {
                            'Copy the following script and paste into the <head> of your page'
                          }
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: 'left',
                            color: '#637381',
                            mt: 2,
                          }}
                        >
                          {scriptsToCopyWithHtml}
                        </Typography>
                      </Stack>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'row',
                          pt: 2,
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={handleBack}
                          sx={{ mr: 3 }}
                        >
                          Go Back
                        </Button>
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              scriptsToCopyToClipboard
                            );
                            setIsCopied(true);
                          }}
                        >
                          Copy Tags
                        </Button>
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={handleNext}
                          sx={{ ml: 3 }}
                        >
                          Next Step
                        </Button>
                      </Box>
                    </Stack>
                  </Card>
                )}
              </>
            )}
          </Box>
        </Container>
      )}
    </>
  );
}
