import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';


const QuickStats = () => {

  const stats = [
    { label: 'Total', value: 465, color: '#00bcd4' },
    { label: 'Pending', value: 12, color: '#ffc107' },
    { label: 'Approved', value: 325, color: '#4caf50' },
    { label: 'Completed', value: 315, color: '#4caf50' },
    { label: 'Canceled', value: 9, color: '#f44336' },
    { label: 'No Show', value: 19, color: '#bdbdbd' },
  ];

  return (
    <Grid container spacing={2}>
      {stats.map((stat) => (
        <Grid item xs={12} sm={12} md={12} key={stat.label}>
          <Paper style={{
    padding: '16px',
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
  }} >
            
            <Typography variant="subtitle1" style={{
    marginRight: '8px',
    display:'flex',
    alignItems:'center'
  }}>
             <Box style={{ backgroundColor: stat.color, width: '12px', height: '12px', borderRadius: '50%', marginRight: '8px' }} /> {stat.label}
            </Typography>
            <Typography variant="subtitle1">{stat.value}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default QuickStats;