import { MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './BarChart.css'
const BUFFER_OPTIONS = [
    {
        label:'2023',
        value:2023
    },
    {
        label:'2024',
        value:2024
    },
]
const BarChart = ({heading}) => {
    const [age, setAge] = useState('2023')
  const [series, setSeries] = useState([
    {
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    },
    {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    },
    // {
    //   name: 'Free Cash Flow',
    //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    // }
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    yaxis: {
      title: {
        text: '$ (thousands)'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        }
      }
    }
  });
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
        <div className='removeLegend' style={{
            display:'flex',
            justifyContent:'space-between'
        }}>

        <Typography variant="h4" sx={{ mb: 3 }}>
        {heading}
        </Typography>
        <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value={2023}>2023</MenuItem>
        <MenuItem value={2024}>2024</MenuItem>
      </Select>
        </div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default BarChart;
