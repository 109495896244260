import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert } from '@mui/material';
import SettingsFrom from '../sections/@dashboard/settings/Form';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';
import PaymentSettingsForm from 'src/sections/@dashboard/payment_settings/Form';

export default function PaymentSettingsPage() {
  const { user, setGlobalUser } = useAuthContext();
  const [success, setSuccess] = useState(null);

  const updateUser = useCallback(async (userData) => {
    const response = await axios.put(`/user`, userData);
    setGlobalUser(response.data.user);
    setSuccess({
      bg: 'success',
      message: 'Settings Updated.',
    });
    setTimeout(() => setSuccess(null), 6000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Payment Settings | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4">
          Payment Settings
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, mt: 1 }} style={{ color: "#637381", fontSize: 13, fontWeight: 400 }}>
          Configure how you wish to receive payments for your bookings on this screen
        </Typography>

        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mt: 2, mb: 2 }}>
            {success.message}
          </Alert>
        )}

        <PaymentSettingsForm config={user.config} updateUser={updateUser} />
      </Container>
    </>
  );
}
