import { useMemo, useCallback, useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import {
  Box,
  Stack,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Grid,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import FormProvider, {
  RHFSelect,
  RHFUploadServiceImage,
  RHFTextField,
} from '../../../components/hook-form';
import axios from '../../../utils/Axios';
import WorkingHours from '../../../components/working-hours';
import { formatDuration } from '../../../utils/formatTime';
import { getValidConfig } from '../../../utils/schedule';
import { useAuthContext } from 'src/auth/useAuthContext';

const DURATIONS_OPTIONS = [
  0, 15, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600
];

const BUFFER_OPTIONS = [
  { label: 'No Buffer', value: 0 },
  { label: '5 Mins', value: 5 },
  { label: '10 Mins', value: 10 },
  { label: '15 Mins', value: 15 },
  { label: '20 Mins', value: 20 },
  { label: '30 Mins', value: 30 },
  { label: '45 Mins', value: 45 },
  { label: '60 Mins', value: 60 },
];

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 2.5,
}));

let currentImage = '';
export default function ServicesModal({
  openModal,
  onCloseModal,
  service,
  categories,
  createService,
  updateService,
  isEdit,
  onOpenDialog,
}) {
  const { user } = useAuthContext();

  const defaultValues = useMemo(
    () => ({
      name: service?.name || '',
      previewImage: service?.previewImage || '',
      duration: service?.duration || 30,
      durationType: service?.durationType || 'Minutes',
      durationTypeValue: service?.durationTypeValue || 30,
      category: service?.category?._id ? service?.category?._id : '',
      config: service
        ? getValidConfig(service.config, {
          shouldIncludeTimezone: false,
          shouldIncludeStatus: false,
        })
        : {},
      hidden: service?.hidden ? service?.hidden : false,
      description: service?.description ? service?.description : '',
      bufferDays: service?.bufferDays ? service?.bufferDays : "0",
      capacity: service?.capacity ? service?.capacity : 1,
      price: service?.price ? service?.price : 0,
    }),
    [service]
  );

  const methods = useForm({ defaultValues });
  const [tab, setTab] = useState(0);
  const [image, setImage] = useState(null);

  const [availability, setAvailability] = useState([]);
  const [formErrors, setFormErrors] = useState(null);
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    if (defaultValues && defaultValues.config.availability) {
      setAvailability(defaultValues.config.availability);
    }
    return () => {
      currentImage = '';
    };
  }, [isEdit, service]);

  const onSubmit = async (data) => {
    if (formErrors) {
      setFormErrors(null);
    }

    if (data.durationType === 'Minutes' && (data.durationTypeValue < 0 || data.durationTypeValue > 60)) {
      setFormErrors({ message: 'Duration must be between 0 and 60 minutes for minutes type' });
      return;
    }

    if (data.durationType === 'Hours' && (data.durationTypeValue < 0 || data.durationTypeValue > 24)) {
      setFormErrors({ message: 'Duration must be between 0 and 24 hours for hours type' });
      return;
    }

    data.config.availability = availability.filter(
      (day) => day.startDate !== 'off' && day.endDate !== 'off'
    );

    try {
      if (image && !currentImage) {
        const response = await axios.post('/upload', { path: 'services' });
        const signData = response.data;

        const formData = new FormData();
        formData.append('file', image);

        const url = `https://api.cloudinary.com/v1_1/de7fg4j42/image/upload?api_key=437499416445219&timestamp=${signData.timestamp}&signature=${signData.signature}&discard_original_filename=true&folder=${signData.folder}`;

        const imageResponse = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        const imageJson = await imageResponse.json();
        if (imageJson.url) {
          currentImage = imageJson.url;
        } else {
          throw new Error("Couldn't upload the preview image try again later.");
        }
      }

      if (isEdit) {
        await updateService({
          ...data,
          previewImage: !currentImage ? data.previewImage : currentImage,
        });
      } else {
        await createService({
          ...data,
          previewImage: !currentImage ? data.previewImage : currentImage,
        });
      }

      handleCloseModal();
      reset();
    } catch (error) {
      setFormErrors({ message: error.messages.join('\n') });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        currentImage = null;
        setValue('previewImage', newFile, { shouldValidate: true });
        setImage(file);
      }
    },
    [setValue]
  );

  const handleTabChange = (_, newTab) => {
    setTab(newTab);
  };

  const handleCloseModal = () => {
    onCloseModal();
    setFormErrors(null);
    setTab(0);
    setAvailability([]);
  };

  const hasAvilability = availability.some((time) => time.startDate !== 'off');

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      {!!formErrors && <Alert severity="error">{formErrors.message}</Alert>}
      <ToolBar>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              onChange={(_, val) =>
                setValue('hidden', !val, { shouldValidate: true })
              }
              defaultChecked={!defaultValues.hidden}
            />
          }
          label="Status"
        />
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          {isEdit && (
            <Tooltip title="Delete">
              <IconButton size="small" onClick={onOpenDialog}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Close">
            <IconButton onClick={handleCloseModal}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Tooltip>
        </Stack>
      </ToolBar>
      <Divider />
      <Stack sx={{ p: 2 }}>
        <Typography variant="h5">Service</Typography>
      </Stack>

      <Scrollbar>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
            <Tab label="Details" />
            <Tab label="Working Hours" />
          </Tabs>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <TabPanel value={tab} index={0}>
            <Stack sx={{ p: 2 }}>
              <Grid
                container
                mb={3}
                sx={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <Grid item xs={12} md={3}>
                  <RHFUploadServiceImage
                    multiple
                    thumbnail
                    name="previewImage"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onUpload={() => console.log('ON UPLOAD')}
                    sx={{ height: 90, width: 90 }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <StyledLabel>Name:</StyledLabel>
                  <RHFTextField
                    name="name"
                    size="small"
                    InputProps={{
                      sx: { typography: 'body2' },
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <Stack>
                  <StyledLabel>Category</StyledLabel>
                  <RHFSelect native name="category" size="small">
                    <option value="">Select Category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </RHFSelect>
                </Stack>

                <Stack>
                  <StyledLabel>Duration Type</StyledLabel>
                  <RHFSelect native name="durationType" size="small">
                    <option value="Minutes">Minutes</option>
                    <option value="Hours">Hours</option>
                  </RHFSelect>
                </Stack>

                <Stack>
                  <StyledLabel>Duration</StyledLabel>
                  <RHFTextField name="durationTypeValue" size="small" type="number" />
                </Stack>

                <Stack>
                  <StyledLabel>Buffer Time Before:</StyledLabel>
                  <RHFSelect native name="config[][beforeBuffer]" size="small">
                    {BUFFER_OPTIONS.map((buffer, index) => (
                      <option key={index} value={buffer.value}>
                        {buffer.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Stack>



                <Stack>
                  <StyledLabel>Buffer Time After:</StyledLabel>
                  <RHFSelect native name="config[][afterBuffer]" size="small">
                    {BUFFER_OPTIONS.map((buffer, index) => (
                      <option key={index} value={buffer.value}>
                        {buffer.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Stack>
                <Stack>
                  <StyledLabel>Customers can't schedule within</StyledLabel>
                  <div className='flex items-center' style={{ position: 'relative' }}>
                    <RHFTextField
                      name="bufferDays"
                      size="small"
                      InputProps={{
                        sx: { typography: 'body2' },
                      }}
                    />
                    <div style={{ position: 'absolute', top: '1px', right: '1px', background: '#ededed', padding: '5px 10px', borderTopRightRadius: '7px', borderBottomRightRadius: '7px' }}>
                      days
                    </div>
                  </div>
                </Stack>

                <Stack>
                  <StyledLabel>Maximum Capacity</StyledLabel>
                  <RHFTextField name="capacity" size="small" type="number" />
                </Stack>

                {user.config.enablePayments ? <Stack>
                  <StyledLabel>Price</StyledLabel>
                  <RHFTextField name="price" size="small" type="number" />
                </Stack> : null}
              </Box>
              <Stack sx={{ mt: 3, mb: 3 }}>
                <StyledLabel>Description:</StyledLabel>
                <RHFTextField
                  multiline
                  name="description"
                  rows={4}
                  size="small"
                />
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box sx={{ p: 2 }}>
              {!hasAvilability && (
                <Alert severity="warning" sx={{ mb: 3 }}>
                  <Typography>
                    Service working hours are being set from the global working
                    hours, change one the days below and save to use custom
                    service hours.
                  </Typography>
                </Alert>
              )}
              {tab && (
                <WorkingHours
                  availability={availability}
                  setAvailability={setAvailability}
                />
              )}
            </Box>
          </TabPanel>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            flexGrow={1}
            mr={2}
          >
            <Button
              onClick={handleCloseModal}
              size="medium"
              color="inherit"
              variant="outlined"
            >
              cancel
            </Button>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              loading={isSubmitting}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Scrollbar>
    </Drawer>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Stack sx={{ mt: 2 }}>{children}</Stack>
    </div>
  );
}

function ToolBar({ children }) {
  return (
    <Stack pt={3} pb={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        flexGrow={1}
        pr={2}
      >
        {children}
      </Stack>
    </Stack>
  );
}
