import { Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import BarChart from 'src/components/charts/BarChart'
import QuickStats from 'src/components/charts/QuickStats'

const Reports = () => {
    return (
        <div>
            <Box
                display="grid"
                columnGap={3}
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 65%) 1fr',
                }}
            >
                <div>  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{ p: 3, height: '100%' }}>

                            <BarChart heading={"Booking Reports"}/>

                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ p: 3, height: '100%' }}>


                        <BarChart heading={"Revenue Reports"}/>

                        </Card>
                    </Grid>
                </Grid></div>
                <div>
                    <Card sx={{ p: 3, height: '100%' }}>

                    <Typography variant="h4" sx={{ mb: 3 }}>
        Quick Stats
        </Typography>
                    <QuickStats/>
                    </Card>
                </div>
            </Box>

        </div>
    )
}

export default Reports
