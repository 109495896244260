import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Card, Grid, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/auth/useAuthContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify';
import palette from 'src/theme/palette';
import axios from '../utils/Axios';

const steps = ['Install App', 'Add Site ID', 'Connected'];


const ConnectWebflow = () => {
    const { user, setGlobalUser } = useAuthContext();
    const [activeStep, setActiveStep] = useState(0);
    const [success, setSuccess] = useState(null);

    const defaultValues = useMemo(
        () => ({
            websiteUrl: '',
        }),
        []
    );
    const methods = useForm({ defaultValues });
    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const onSubmit = async (data) => {
        let userData = { ...user };
        userData.config.webflowSiteId = data.websiteUrl;

        userData = {
            config: userData.config
        }

        const response = await axios.put(`/user`, userData);
        setGlobalUser(response.data.user);
        setSuccess({
            bg: 'success',
            message: 'Settings Updated.',
        });
    }

    useEffect(() => {
        if (user.config.webflowSiteName) {
            setActiveStep(2);
        } else {
            setActiveStep(0);
        }
    }, [user.config.webflowSiteName])

    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={8}
            >
                <Typography variant="h4" gutterBottom>
                    Connect Webflow
                </Typography>
            </Stack>
            <Stepper style={{ width: '82%', margin: 'auto' }} activeStep={activeStep}>
                {steps.map((label, _) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep == 0 && <Card style={{ marginTop: '3rem' }} sx={{ p: 3 }}>
                <Typography style={{ textAlign: 'center' }} variant="h5" gutterBottom>
                    Install Webflow App
                </Typography>
                <Typography style={{ textAlign: 'center' }} variant="subtitle1" sx={{
                    opacity: '0.5',
                }} gutterBottom>
                    Install our webflow app and authorize the site you want to connect FlowBookings with.
                </Typography>

                <div style={{ marginTop: '1rem' }}>
                    <Box style={{ width: "780px", margin: 'auto', background: '#f8f8f8', padding: '1.5rem', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Installation Instructions
                            </Typography>
                            <Typography variant="body2" sx={{
                                opacity: '0.5',
                            }} gutterBottom>
                                Follow the link and authorize the webflow project/site you wish to connect with FlowBookings
                            </Typography>
                        </Box>
                        <Box style={{
                            width: '270px'
                        }}>
                            <Button
                                variant="contained"
                                endIcon={<Iconify icon="ic_pop" />}
                                onClick={(event) => {

                                }}
                            >
                                Install Webflow App
                            </Button>
                        </Box>
                    </Box>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            style={{ margin: '1rem' }}
                            variant="outlined"
                            size="medium"
                            color="inherit"
                            onClick={(event) => handleNext()}
                        >
                            Next Step
                        </Button>
                    </div>
                </div>

            </Card>}
            {activeStep == 1 && <Card style={{ marginTop: '3rem' }} sx={{ p: 3 }}>
                <Typography style={{ textAlign: 'center' }} variant="h5" gutterBottom>
                    Connect Your Webflow Site
                </Typography>
                <Typography style={{ textAlign: 'center' }} variant="subtitle1" sx={{
                    opacity: '0.5',
                }} gutterBottom>
                    Add your site ID to connect your webflow project
                </Typography>

                {success != null && (
                    <Alert severity={success.bg} color="info" sx={{ mt: 2, mb: 2 }}>
                        {success.message}
                    </Alert>
                )}

                <div style={{ marginTop: '1rem' }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ display: 'flex', gap: '1rem', margin: 'auto', width: '500px' }}>
                            <RHFTextField placeholder='Enter Your Site Id' name="websiteUrl" rows={4} size="small" /><LoadingButton
                                type="submit"
                                size="medium"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </FormProvider>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            style={{ margin: '1rem' }}
                            variant="outlined"
                            size="medium"
                            color="inherit"
                            onClick={(event) => handleNext()}
                        >
                            Next Step
                        </Button>
                    </div>
                </div>

            </Card>}
            {activeStep == 2 && <Card style={{ marginTop: '3rem' }} sx={{ p: 3 }}>

                {user.config.webflowSiteName ? <>
                    <Typography style={{ textAlign: 'center' }} variant="h5" gutterBottom>
                        You’re All Set!
                    </Typography>
                    <Typography style={{ textAlign: 'center' }} variant="subtitle1" sx={{
                        opacity: '0.5',
                        width: '70%',
                        margin: 'auto'
                    }} gutterBottom>
                        Congratulations! You are all set up. You can see the connected site below and proceed to the webflow app to create and add booking forms to your site.</Typography>
                </> : <></>}

                <div style={{ marginTop: '1rem' }}>
                    <Box style={{ width: "580px", margin: 'auto', padding: '1.5rem', display: "flex", justifyContent: "space-between", alignItems: "center", border: '1px solid #f1f1f1' }}>
                        {user.config.webflowSiteName ? <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Connected Site
                            </Typography>
                            <Typography variant="body2" sx={{
                                opacity: '0.8',
                            }} gutterBottom>
                                <span style={{ opacity: '0.6' }}> Name </span> : {user.config.webflowSiteName}
                            </Typography>
                            <Typography variant="body2" sx={{
                                opacity: '0.8',
                            }} gutterBottom>
                                <span style={{ opacity: '0.6' }}> Site ID </span> : {user.config.webflowSiteId}
                            </Typography>
                        </Box> : <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Site Not Connected
                            </Typography>
                            <Typography variant="body2" sx={{
                                opacity: '0.8',
                            }} gutterBottom>
                                Please visit the webflow app to connect your site, and also do check if you have added the site ID correctly.
                            </Typography>
                        </Box>}
                        <Box style={{
                            width: '150px'
                        }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={(event) => {
                                    let userData = { ...user };
                                    userData.config.webflowSiteId = '';
                                    userData.config.webflowSiteName = '';
                                    userData = {
                                        config: userData.config
                                    }

                                    axios.put(`/user`, userData).then(response => {
                                        setGlobalUser(response.data.user);
                                        setActiveStep(1);
                                    })
                                }}
                            >
                                Disconnect Site
                            </Button>
                        </Box>
                    </Box>
                </div>

            </Card>}
        </div>
    )
}

export default ConnectWebflow
